@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: white;
}

* {
  color: black;
}
